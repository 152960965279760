import { Helmet } from "react-helmet";
import { Button, Text, Heading, Slider, Img } from "../../components";
import Header from "../../components/Header";
import LogoutButton from "../../components/LogoutButton";
import { Menu } from "components/Menu";
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function ErrorPage() {
  let navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    // Ajouter le script Stripe
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    // Nettoyer le script lorsque le composant est démonté
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Page d'accueil</title>
      </Helmet>
      <div className="flex flex-col px-6 py-20 text-base font-bold leading-6 text-white bg-[linear-gradient(180deg,#280F24_0%,#550038_100%)] max-md:px-5">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa235a897acda2436f6721b4953161efb08fda4833f7b07d6978f8fdd6bb928d?"
          className="w-7 aspect-square"
          onClick={() => navigate(-1)}
        />
        <div className="self-start mt-9 text-3xl text-white">
          Bonjour {user?.name} !
        </div>
        <div className="container-xs md:p-5">
          <div className="flex flex-col items-center gap-[53px] sm:gap-[26px]">
            {/* Conteneur pour le bouton Stripe */}
            <div id="stripe-button-container">
              <stripe-buy-button
                buy-button-id="buy_btn_1PaKOMB1t8Vrl3tJkgOxfama"
                publishable-key="pk_live_WmAUW3DDqWxp5qfGMzYKAX2D"
              >
              </stripe-buy-button>
            </div>
            <Img
              src={user?.picture}
              alt="user image"
              className="h-[77px] w-[138px] object-contain"
            />
          </div>
        </div>
        <div className="self-start mt-4 text-xl font-medium">
          Email: {user?.email}
          <br />
          Nom: {user?.given_name}
          <br />
          Prénom: {user?.family_name}
          <br />
          Vous n'avez malheureusement pas accès à l'application pour le moment, si le paiement a déjà été fait veuillez nous contacter via notre formulaire de contact.
        </div>
        <div className="h-[100px] flex flex-col items-center gap-[530px] sm:gap-[260px]"></div>

        <LogoutButton />

        <div className="h-[200px] flex flex-col items-center gap-[530px] sm:gap-[260px]"></div>

        
      </div>
    <Menu></Menu>
    </>
  );
}

