import { Img, Heading } from "./..";
import React from "react";

export default function Header({ ...props }) {
  return (
    <header {...props} className={`${props.className} flex items-center py-[11px]`}>
      <div className="container-xs flex md:p-5">
       {/* <div className="flex w-full items-center justify-between gap-5">
          <Heading as="h6" className="tracking-[0.20px]">
            9:41
          </Heading>
          <div className="flex items-center gap-[5px] self-end">
            <Img src="images/img_thumbs_up.svg" alt="thumbsupicon" className="h-[10px] self-end" />
            <Img src="images/img_settings.svg" alt="settingsicon" className="h-[10px]" />
            <Img src="images/img_instagram.svg" alt="instagramicon" className="h-[13px]" />
          </div>
  </div>*/}
      </div>
    </header>
  );
}
