import React from "react";
import { NavLink, useLocation, useNavigate, matchPath } from "react-router-dom";

const Menu = ({ children }) => {
  const navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  const location = useLocation();
  console.log("location", location);

  return (
    <>
      <div className="w-full menu-bottom sticky bottom-0 text-white bg-gradient1 flex justify-between items-start p-8 pt-3 pb-5">
        <NavLink
          className={({ isActive }) => (isActive ? "selected" : "")}
          to="/boiteaoutil"
        >
          <button className="text-xs font-bold flex flex-col justify-center items-center">
            <img className="menu-selected" src="images/home.svg" alt="home" />
            <span className="mt-2">Boite à outil</span>
          </button>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "selected" : "")}
          to="/imagine"
        >
          <button className="text-xs font-bold flex flex-col justify-center items-center">
            <img
              className="menu-selected"
              src="images/discovery.svg"
              alt="discovery"
            />
            <span className="mt-2">Imaginez</span>
          </button>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "selected" : "")}
          to="/explore"
        >
          <button className="text-xs font-bold flex flex-col justify-center items-center">
            <img
              className="menu-selected"
              src="images/category.svg"
              alt="category"
            />
            <span className="mt-2">Explorez</span>
          </button>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "selected" : "")}
          to="/profil"
        >
          <button className="text-xs font-bold flex flex-col justify-center items-center">
            <img
              className="menu-selected"
              src="images/profil.svg"
              alt="profil"
            />
            <span className="mt-2">Profil</span>
          </button>
        </NavLink>

          <button onClick={() => navigate("/darkwalkthroughfour")} className="fixed right-[0] bottom-[0]" >+</button>
       
        

      </div>
    </>
  );
};

export { Menu };
