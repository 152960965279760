import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Header from "../../components/Header";
import React, { useState, useRef, useEffect } from "react";
import { Loading } from "components/Loading";
import ReactModal from "react-modal";
import { Generate } from "components/Generate";
import { Menu } from "components/Menu";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function ImaginePage() {
  ReactModal.setAppElement("#root");

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSize, setSelectedSize] = useState(1);
  const [selectedShadow, setSelectedShadow] = useState(1);
  const [promptText, setPromptText] = useState("");
  const [formData, setFormData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [prompt, setPrompt] = useState("");

  const promptParams = searchParams.get("prompt");

  let navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  useEffect(() => {
    if (promptParams) {
      setPrompt(promptParams);
    }
  }, [promptParams]);

  useEffect(() => {
    if (prompt) {
      let text = "";
      switch (prompt) {
        case "1":
          text =
            "the image displays a bottle of wine bathed in a golden light with warm tones and gleaming highlights in the background";
          break;
        case "2":
          text =
            "a bottle of wine on a wooden table, with a wine shop in the background";
          break;
        case "3":
          text =
            "a wine bottle on a stone, with a mountain  in the background";
          break;
        case "4":
          text =
            "the image displays a bottle of wine bathed in a golden light with warm tones and gleaming highlights in the background";
          break;
        case "5":
          text =
            "a bottle of champagne on a black plastic counter top, with a black kitchen counter in the background";
          break;

        case "6":
          text =
            "a bottle of red wine on a black dining room table, with a wooden kitchen counter in the background";
          break;
        
        case "7":
          text =
            "a bottle of wine on a black dining room table, with a modern restaurant in the background";
          break;

          case "8":
            text =
              "a bottle of wine on a black oak table, with a cellar shop in the background";
            break;
  
          case "9":
            text =
              "a beach in the background";
            break;
          
          case "10":
            text =
              "a bottle of wine on a fresh grass, with a forest landscape in the background";
            break;
            case "11":
              text =
                "a bottle of wine on a wood table with a plate of oisters in the background";
              break;
            case "12":
              text =
                "a wine bottle on a stone, with a mountain  in the background";
              break;
            case "13":
              text =
                "a bottle of wine on a marble countertop, with a minimalist natural light background";
              break;
            case "14":
              text =
                "a bottle of wine on marble countertop, with golden marble table in the background";
              break;
            case "15":
              text =
                "a bottle of wine with minimalist studio daily light in the background copie";
              break;
    
            case "16":
              text =
                "a bottle of wine on a white paper, with minimalist studio light in the background";
              break;
            
            case "17":
              text =
                "a bottle of champagne on a glass surface, with an italian marble black and white in the background";
              break;
    
              case "18":
                text =
                  "a bottle of red wine on a dark wooden table, with a blue wall in the background";
                break;
      
              case "19":
                text =
                  "a bottle of wine on a black marble, with a minimalist warm grey in the background";
                break;
              
              case "20":
                text =
                  "a bottle of wine on a rustic wooden table, with a forest landscape in the background";
                break;
                case "21":
                  text =
                    "a bottle of champagne on a blue metal table, with a bokeh in eiffel tower in the background";
                  break;
                case "22":
                  text =
                    "a bottle of wine on glitters, with a city skyline in the background";
                  break;
                case "23":
                  text =
                    "the image displays a bottle of wine bathed in a golden light with warm tones and gleaming highlights in the background";
                  break;
                case "24":
                  text =
                    "the image displays a bottle of wine bathed in a golden light. It has been captured professionally, emphasizing the warm tones and gleaming highlights";
                  break;
                
        default:
          break;
      }

      setPromptText(text);
      console.log("text", text);
    }
  }, [prompt]);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectedImage(fileUploaded);
  };

  const generate = () => {
    let outputSize = "";
    let shadowMode = "";
    switch (selectedSize) {
      case 2:
        outputSize = "1080x1920";
        break;
      case 3:
        outputSize = "1920x1080";
        break;
      case 4:
        outputSize = "1200x1600";
        break;
      default:
        outputSize = "1500x1500";
        break;
    }
    switch (selectedShadow) {
      case 2:
        shadowMode = "ai.floating";
        break;
      case 3:
        shadowMode = "ai.hard";
        break;
      default:
        shadowMode = "ai.soft";
        break;
    }
    let backgroundPrompt = promptText;

    const form = new FormData();
    // native seeds https://docs.photoroom.com/image-editing-api-plus-plan/ai-backgrounds
    form.append("background.seed", 117879368);
    form.append("background.prompt", backgroundPrompt);
    form.append("imageFile", selectedImage);
    form.append("outputSize", outputSize);
    form.append("shadow.mode", shadowMode);
    form.append("margin", 0.1);

    setFormData(form);
    setOpenModal(true);
  };

  return (
    <>
      <Helmet>
        <title>Imaginez</title>
        <meta name="description" content="version zero." />
      </Helmet>

      {/* status bar section */}
      <div className="w-full h-full min-h-screen h-dvh bg-gradient1 text-white">
        <div className="flex flex-col container-xs gap-[30px] sm:gap-[20px] p-5 pt-8">
          {/* logo and loading section */}
          <div>
            <img alt="logo" className="h-8" src="images/logo.png" />
          </div>
          <div className="text-arrow">
            <span className="text-2xl font-bold">Imaginez</span>
            <span className="text-red-300 text-lg " onClick={() => routeTo("/darkwalkthroughfour")}>
              Nous contacter &#x2192;
            </span>
          </div>
          <div className="flex justify-center items-center">
            <button className="rounded h-48 w-48 upload" onClick={handleClick}>
              <div className="flex flex-col items-center text-red-300">
                <span className="text-6xl">+</span>
                <span>Téléchargez</span>
              </div>
              {selectedImage && (
                <img alt="not found" src={URL.createObjectURL(selectedImage)} />
              )}
            </button>
            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
          </div>
          <div className="text-arrow">
            <span className="text-2xl font-bold">Choisissez la taille</span>
          </div>
          <div className="flex flex-row justify-between">
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 1 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(1)}
            >
              <span className="format-square"></span>1 : 1
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 2 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(2)}
            >
              <span className="format-portrait"></span>9 : 16
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 3 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(3)}
            >
              <span className="format-rectangle"></span>16 : 19
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 4 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(4)}
            >
              <span className="format-quarter"></span>3 : 4
            </button>
          </div>

          <div className="text-arrow">
            <span className="text-2xl font-bold">Ombre</span>
          </div>
          <div className="flex flex-row justify-between">
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 1 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(1)}
            >
              Douce
            </button>
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 2 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(2)}
            >
              Moyenne
            </button>
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 3 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(3)}
            >
              Forte
            </button>
          </div>
          <div>
            <textarea
              className="rounded bg-black-700 w-full h-48 p-5"
              placeholder="Décrivez ce que vous souhaitez derrière votre produit (en anglais si possible).
Par exemple : a table of marble"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea>
          </div>
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Explorez</span>
              <button
                onClick={() => routeTo("/explore")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div
              id="explorez"
              className="flex justify-between gap-2 mt-5 overflow-auto w-full"
            >
              <button
                onClick={() => setPrompt("1")}
                style={{
                  backgroundImage: `url('images/bottles/1.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32 "
                alt="bottle"
              >
                <span
                  className={
                    prompt === "1" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("2")}
                style={{
                  backgroundImage: `url('images/bottles/2.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "2" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("3")}
                style={{
                  backgroundImage: `url('images/bottles/3.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "3" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("4")}
                style={{
                  backgroundImage: `url('images/bottles/4.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "4" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("5")}
                style={{
                  backgroundImage: `url('images/bottles/5.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "5" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("6")}
                style={{
                  backgroundImage: `url('images/bottles/6.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "6" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("7")}
                style={{
                  backgroundImage: `url('images/bottles/7.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "7" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("8")}
                style={{
                  backgroundImage: `url('images/bottles/8.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "8" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("9")}
                style={{
                  backgroundImage: `url('images/bottles/9.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "9" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("10")}
                style={{
                  backgroundImage: `url('images/bottles/10.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "10" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("11")}
                style={{
                  backgroundImage: `url('images/bottles/11.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "11" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("12")}
                style={{
                  backgroundImage: `url('images/bottles/12.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "12" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("13")}
                style={{
                  backgroundImage: `url('images/bottles/13.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "13" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("14")}
                style={{
                  backgroundImage: `url('images/bottles/14.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "14" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("15")}
                style={{
                  backgroundImage: `url('images/bottles/15.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "15" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("16")}
                style={{
                  backgroundImage: `url('images/bottles/16.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "16" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("17")}
                style={{
                  backgroundImage: `url('images/bottles/17.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "17" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("18")}
                style={{
                  backgroundImage: `url('images/bottles/18.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "18" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("19")}
                style={{
                  backgroundImage: `url('images/bottles/19.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "19" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("20")}
                style={{
                  backgroundImage: `url('images/bottles/20.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "20" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("21")}
                style={{
                  backgroundImage: `url('images/bottles/21.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "21" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("22")}
                style={{
                  backgroundImage: `url('images/bottles/22.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "22" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("23")}
                style={{
                  backgroundImage: `url('images/bottles/23.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "23" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
              <button
                onClick={() => setPrompt("24")}
                style={{
                  backgroundImage: `url('images/bottles/24.png')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="btn-border-gold shrink-0 rounded-lg w-24 h-32"
                alt="bottle"
              >
                <span
                  className={
                    prompt === "24" ? "select-prompt" : "unselect-prompt"
                  }
                >
                  <img src="images/check.svg" alt="check"></img>
                </span>
              </button>
            </div>
          </div>
          <div>
            <button
              className="rounded-full font-bold btn-gold w-full h-16"
              onClick={() => generate()}
            >
              Generer
            </button>
          </div>
        </div>

        <ReactModal
          style={{ overlay: { zIndex: 100 } }}
          className="bg-gradient1"
          isOpen={openModal}
        >
          <Generate
            form={formData}
            close={() => setOpenModal(false)}
          ></Generate>
        </ReactModal>
        <Menu></Menu>
      </div>
    </>
  );
}
