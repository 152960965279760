import { Helmet } from "react-helmet";
import { Img, Text, Heading, Slider, Button } from "../../components";
import Header from "../../components/Header";
import LogoutButton from "../../components/LogoutButton";
import LoginButton from "../../components/LoginButton";
import React, { useState, useEffect, useContext } from "react"; // Ajoutez useContext ici
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "components/Footer";
import Popup from "components/Popup";
import { useAuth0 } from "@auth0/auth0-react"; // Importez useAuth0

export default function DarkwalkthroughTwoPage() {
  const [sliderState, setSliderState] = React.useState(0);
  const [sliderEnd, setSliderEnd] = React.useState(false);
  const sliderRef = React.useRef(null);
  const texts = [
    {
      heading: "Transformez votre bouteille",
      description: "en une photo réaliste et captivante !"
    },
    {
      heading: "Boostez votre marque",
      description: "Avec du contenu visuel percutant, personalisé à votre image."
    },
    {
      heading: "Votre créativité sans limite",
      description: "Détourage, effets d’ombres, de lumières, et d’ambiance,..."
    }
  ];

  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>; // Vous pouvez remplacer cela par un composant de chargement personnalisé
  } else if (isAuthenticated) {
    return <Navigate to="/imagine" />;
  } 

  const setNext = (e) => {
    if(sliderEnd){
      navigate("/darkwelcomescreen");
    }else{
      sliderRef?.current?.slideNext(e);
    }
  };

  return (
    <>
      <Popup></Popup>
      <Helmet>
        <title>Transform Your Bottle - Interactive Walkthrough</title>
        <meta
          name="description"
          content="Learn how to transform your bottle into a realistic and captivating photo with our easy-to-follow walkthrough. Get started now!"
        />
      </Helmet>

      {/* main layout section */}
      <div className="w-full  min-h-screen h-dvh  bg-gradient1">
        <div>
          {/* header section */}
          <Header />

          {/* content section */}
          <div className="relative h-[100%] md:h-auto">
            <div className="sliderview flex  w-full">
              <Slider
                autoPlay
                autoPlayInterval={4000}
                responsive={{ 0: { items: 1 }, 550: { items: 1 }, 1050: { items: 1 } }}
                renderDotsItem={(props) => {
                  return props?.isActive ? (
                    <div className="mr-1.5 inline-block h-[8px] w-[32px] cursor-pointer rounded bg-gradient3" />
                  ) : (
                    <div className="mr-1.5 inline-block h-[8px] w-[8px] cursor-pointer rounded bg-gradient1" />
                  );
                }}
                activeIndex={sliderState}
                onSlideChanged={(e) => {
                  if(e?.slide >= 2){
                    // slider show 3 items
                    setSliderEnd(true);
                  }
                  setSliderState(e?.item);
                }}
                ref={sliderRef}
              
                items={Array(3).fill().map((_, i) => (
                  <React.Fragment key={i}>
                    <div className="relative h-[100%] md:h-auto">{/*h-[730px]*/} 
                      <div className="flex w-full flex-col">
                        <div className="container-xs relative z-[1]  md:p-5 md:px-5">{/*px-[448px]*/}
                          <div className="relative  md:h-auto">{/*h-[650px]*/}
                            <Img
                              src={`images/img_image.png`}
                              alt={`first image ${i}`}
                              className="h-[100%] w-full object-cover"
                            />
                            <Img
                              src={`images/img_group${i}.png`}
                              alt={`second image ${i}`}
                              className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[auto] w-[90%] object-cover"
                            />
                          </div>
                        </div>
                        <div className="relative mt-[-60px] h-[140px] bg-gradient2" />
                      </div>
                      <div className="absolute bottom-[0.00px] left-0 right-0 z-[2] m-auto flex w-full justify-center bg-gradient1 py-6 sm:py-5">
                        <div className="container-xs mb-14 mt-2 flex md:p-5">
                          <div className="flex w-full flex-col text-center items-center justify-center gap-[31px]">
                            <Heading size="s" as="h2">
                              {texts[i].heading}
                            </Heading>
                            <Text as="p" className="tracking-[0.20px]">
                              {texts[i].description}
                            </Text>
                            <div className="h-[50px]" />
                          </div>
                        </div>
                      </div>
                      <Img
                        src={`images/img_rectangle.png`}
                        alt={`image three ${i}`}
                        className="absolute bottom-[42%] left-0 right-0 z-[3] m-auto h-[140px] w-full object-cover"
                      />
                    </div>
                  </React.Fragment>
                ))}
              />
            </div>
          </div>

          {/* footer section */}
          <Footer setNext={setNext} />
        </div>
      </div>
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
    </>
  );
}
