import React from "react";

const Loading = ({ children, isLoading, className = "" }) => {
  return (
    <>
      {isLoading && (
        <div className="loading">
          <img
            className={`${className} h-16`}
            src="images/img_icon_loading_c.png"
            alt="loading"
          ></img>
        </div>
      )}
    </>
  );
};

export { Loading };
