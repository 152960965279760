import * as React from "react";
import { Button, Text, Heading, Slider, Img } from "../../components";
import { Menu } from "components/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function BoiteAOutil() {
  const navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  return (
    <div className="flex flex-col justify-end pt-3 bg-[linear-gradient(180deg,#280F24_0%,#550038_100%)]">
      <div className="flex overflow-auto  h-screen flex-col px-6 py-3 w-full text-white leading-[160%] max-md:px-5 max-md:max-w-full">
        <div className="flex gap-4 py-1.5 text-2xl font-bold text-center max-md:flex-wrap">
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/de2cee991fe5cc682014b68a744bdcedc2eb6cf0cea4036ed2d86140aa6c9e3a?"
            className="shrink-0 my-auto w-7 aspect-square "
          />
          <div className="flex-1 max-md:max-w-full">Boîte à outils</div>
        </div>
        <div className="mt-6 text-lg tracking-wide leading-7 max-md:max-w-full">
          Conseil expert : <br />
          Reculez vous et utilisez le zoom x2 ou x3
          <br />
          Mettez votre objectif au niveau du haut de l’étiquette de votre
          bouteille
        </div>
        <div className="flex gap-5 items-center px-px mt-10 max-md:flex-wrap">
          <Img
            loading="lazy"
            srcSet="images/detourez.png"
            className="shrink-0 self-stretch aspect-square w-[90px] rounded-[10px]"
          />
          <div
            className="flex flex-col flex-1 justify-center self-stretch my-auto max-md:max-w-full"
            onClick={() => routeTo("/detourer")}
          >
            <div className="text-xl font-bold max-md:max-w-full">Détourez</div>
            <div className="mt-1 text-sm tracking-wide max-md:max-w-full">
              Supprimer l’arrière plan avec précision
            </div>
          </div>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aa8b51e0ed4a32a0a8e41218f7b2c9c190e20ab2331273301b78972eac11d63?"
            className="shrink-0 self-stretch my-auto w-6 aspect-square"
          />
        </div>
        <div className="shrink-0 mt-4 h-px border border-solid bg-neutral-700 border-neutral-700 max-md:max-w-full" />
        <div
          className="flex gap-5 items-center px-px mt-4 max-md:flex-wrap"
          onClick={() => routeTo("/imagine")}
        >
          <Img
            loading="lazy"
            srcSet="images/imaginez.png"
            className="shrink-0 self-stretch aspect-square w-[90px] rounded-[10px]"
          />
          <div className="flex flex-col flex-1 justify-center self-stretch my-auto max-md:max-w-full">
            <div className="text-xl font-bold max-md:max-w-full">Imaginez </div>
            <div className="mt-1 text-sm tracking-wide max-md:max-w-full">
              Laissez place à votre créativité
            </div>
          </div>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aa8b51e0ed4a32a0a8e41218f7b2c9c190e20ab2331273301b78972eac11d63?"
            className="shrink-0 self-stretch my-auto w-6 aspect-square"
          />
        </div>
        <div className="shrink-0 mt-4 h-px border border-solid bg-neutral-700 border-neutral-700 max-md:max-w-full" />
        <div
          className="flex gap-5 items-center px-px mt-4 max-md:flex-wrap"
          onClick={() => routeTo("/explore")}
        >
          <Img
            loading="lazy"
            src="images/explorez.png"
            className="shrink-0 self-stretch aspect-square w-[90px] rounded-[10px]"
          />
          <div className="flex flex-col flex-1 justify-center self-stretch my-auto max-md:max-w-full">
            <div className="text-xl font-bold max-md:max-w-full">Explorez</div>
            <div className="mt-1 text-sm tracking-wide max-md:max-w-full">
              en quelques secondes dignes d’un studio photo
            </div>
          </div>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aa8b51e0ed4a32a0a8e41218f7b2c9c190e20ab2331273301b78972eac11d63?"
            className="shrink-0 self-stretch my-auto w-6 aspect-square"
          />
        </div>
        <div className="shrink-0 mt-4 h-px border border-solid bg-neutral-700 border-neutral-700 max-md:max-w-full" />
        <div
          className="flex gap-5 items-center px-px mt-4 max-md:flex-wrap"
          onClick={() => routeTo("/darkwalkthroughfour")}
        >
          <Img
            loading="lazy"
            src="images/imaginez.png"
            className="shrink-0 self-stretch aspect-square w-[90px] rounded-[10px]"
          />
          <div className="flex flex-col flex-1 justify-center self-stretch my-auto max-md:max-w-full">
            <div className="text-xl font-bold max-md:max-w-full">Revalorisation</div>
            <div className="mt-1 text-sm tracking-wide max-md:max-w-full">
            contactez nos équipes pour vous aider à créer le visuel parfait
            </div>
          </div>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aa8b51e0ed4a32a0a8e41218f7b2c9c190e20ab2331273301b78972eac11d63?"
            className="shrink-0 self-stretch my-auto w-6 aspect-square"
          />
        </div>
        <div className="shrink-0 mt-4 h-px border border-solid bg-neutral-700 border-neutral-700 max-md:max-w-full" />
        <div
          className="flex gap-5 items-center px-px mt-4 max-md:flex-wrap"
          onClick={() => routeTo("/RGPD")}
        >
          <Img
            loading="lazy"
            src="images/detourez.png"
            className="shrink-0 self-stretch aspect-square w-[90px] rounded-[10px]"
          />
          <div className="flex flex-col flex-1 justify-center self-stretch my-auto max-md:max-w-full">
            <div className="text-xl font-bold max-md:max-w-full">Conditions Générales</div>
            <div className="mt-1 text-sm tracking-wide max-md:max-w-full">
              nos conditions générales
            </div>
          </div>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9aa8b51e0ed4a32a0a8e41218f7b2c9c190e20ab2331273301b78972eac11d63?"
            className="shrink-0 self-stretch my-auto w-6 aspect-square"
          />
        </div>
      </div>
      <Menu></Menu>
    </div>
  );
}
