import { Helmet } from "react-helmet";
import { Heading, Text, Button, Img } from "../../components";
import Header from "../../components/Header";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function DarkwelcomescreenPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <Helmet>
        <title>Bienvenue - Démarer la transformation de vos bouteilles</title>
        <meta
          name="description"
          content="Join our community and transform your bottles into art! Sign up with Google, Apple, Facebook, or Twitter, or start your registration now."
        />
      </Helmet>

      {/* header section */}
      <div className="w-full  min-h-screen h-dvh  bg-gradient1 pb-[60px] md:pb-5">
        <div className="flex flex-col gap-[60px] sm:gap-[30px]">
          <Header />

          {/* logo and tagline section */}
          <div className="container-xs md:p-5">
            <div className="flex flex-col items-center gap-[53px] sm:gap-[26px]">
              <Img
                src="images/img_header_logo.png"
                alt="logo image"
                className="h-[77px] w-[138px] object-contain"
              />
              <div className="flex justify-center self-stretch">
                <Text as="p" className="mt-[71px] tracking-[0.20px]">
                  Transformez vos bouteilles !
                </Text>
              </div>
              <div className="flex flex-col items-center gap-[53px] self-stretch sm:gap-[26px]">
                {/* authentication buttons section 
                <div className="flex flex-col gap-5 self-stretch">
                  <Button
                    color="gray_900_01"
                    shape="round"
                    //to="../imagine"
                    onClick={() => loginWithRedirect()}
                    leftIcon={
                      <Img
                        src="images/img_frame.svg"
                        alt="frame"
                        className="h-[24px] w-[24px]"
                      />
                    }
                    className="w-full gap-4 border border-solid border-gray-800 sm:px-5"
                  >
                    Continuer avec Google
                  </Button>
                  <Button
                    color="gray_900_01"
                    shape="round"
                    //to="../imagine"
                    onClick={() => loginWithRedirect()}
                    leftIcon={
                      <Img
                        src="images/img_frame_white_a700.svg"
                        alt="frame"
                        className="h-[24px] w-[24px]"
                      />
                    }
                    className="w-full gap-4 border border-solid border-gray-800 sm:px-5"
                  >
                    Continuer avec Apple
                  </Button>
                  <div>
                    <Button
                      color="gray_900_01"
                      shape="round"
                      //to="../imagine"
                      onClick={() => loginWithRedirect()}
                      leftIcon={
                        <Img
                          src="images/img_facebook.png"
                          alt="facebook"
                          className="h-[17px] w-[10px]"
                        />
                      }
                      className="w-full gap-4 border border-solid border-gray-800 sm:px-5"
                    >
                      Continuer avec Facebook
                    </Button>
                  </div>
                  <Button
                    color="gray_900_01"
                    shape="round"
                    //to="../imagine"
                    onClick={() => loginWithRedirect()}
                    leftIcon={
                      <Img
                        src="images/img_frame_blue_500_01.svg"
                        alt="frame"
                        className="h-[24px] w-[24px]"
                      />
                    }
                    className="w-full gap-4 border border-solid border-gray-800 sm:px-5"
                  >
                    Continuer avec Twitter
                  </Button>
                </div>*/}
                <Button
                  shape="round"
                  //to="../imagine"
                  onClick={() => loginWithRedirect()}
                  className="min-w-[320px] sm:px-5"
                >
                  Se connecter
                </Button>

                {/* signup prompt section */}
                <div className="flex flex-wrap justify-center gap-2 self-stretch">
                  <a href="imagine">
                    <Text size="xs" as="p" className="tracking-[0.20px]">
                      Vous n'avez pas de compte?
                    </Text>
                  </a>
                  <a href="imagine" className="self-end">
                    <Heading
                      as="h1"
                      className="!font-bold tracking-[0.20px] !text-red-300"
                      onClick={() => loginWithRedirect()}
                    >
                      S'inscrire
                    </Heading>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
