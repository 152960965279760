import { Button } from "./..";
import React from "react";

export default function Footer({ setNext, ...props }) {
  return (
    <footer
      {...props}
      className={`${props.className} fixed inset-x-0 bottom-0 h-[18%] flex items-start py-6 sm:py-5 border-gray-800 border-t border-solid bg-gradient`}
    >
      <div className="container-xs mb-3 flex md:p-5">
        <div className="flex w-full justify-around">
          <Button
            variant="gradient"
            shape="round"
            color="gray_900_pink_900_01"
            className="w-full"
            to="/darkwelcomescreen"
          >
            Passer
          </Button>
          <button
            className="flex flex-row items-center justify-center text-center cursor-pointer text-white-A700 tracking-[0.20px] text-base font-bold rounded-[29px] rounded-[29px] h-[58px] px-[35px] text-base bg-red-300 text-white-A700"
            onClick={(e) => setNext(e)}
          >
            Suivant
          </button>
        </div>
      </div>
    </footer>
  );
}
