import { Helmet } from "react-helmet";
import { Button, Text, Heading, Slider, Img } from "../../components";
import Header from "../../components/Header";
import { Menu } from "components/Menu";
import * as React from "react";
import { useNavigate } from "react-router-dom";


export default function DarkwalkthroughFourPage() {
  let navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  return (
    <div className="flex flex-col px-6 py-20 text-base font-bold leading-6 text-white bg-[linear-gradient(180deg,#280F24_0%,#550038_100%)] max-md:px-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa235a897acda2436f6721b4953161efb08fda4833f7b07d6978f8fdd6bb928d?"
        className="w-7 aspect-square"
        onClick={() => navigate(-1)}
      />
      <div className="self-start mt-9 text-3xl text-white">
        Ce projet vous intéresse?{" "}
      </div>
      <div className="self-start mt-4 text-xl font-medium">
        Contactez nous pour vous inscrire
      </div>
      <div onClick={() => window.location.href = "mailto:michael@boudot.me"} className="mt-20 tracking-wide max-md:mt-10 max-md:max-w-full">
        Email
      </div>
      <div className="flex gap-3 mt-3.5 tracking-wide whitespace-nowrap max-md:flex-wrap">
        <div onClick={() => window.location.href = "mailto:michael@boudot.me"} className="flex-1 max-md:max-w-full">michael@boudot.me</div>
        <img
          onClick={() => window.location.href = "mailto:michael@boudot.me"}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/72abce4ad79fdce66819750b785186ad9d63272e5a1b00baa34b8cc335d20410?"
          className="shrink-0 w-7 aspect-square"
        />
      </div>
      <div className="shrink-0 mt-2 h-px bg-stone-400 rounded-[100px] max-md:max-w-full" />
      <div onClick={() => window.location.href = "https://api.whatsapp.com/send/?phone=33753070616&text&type=phone_number&app_absent=0"} className="mt-10 tracking-wide max-md:max-w-full">Whatsapp</div>
      <div className="flex gap-3 mt-9 tracking-wide whitespace-nowrap max-md:flex-wrap max-md:mr-1">
        <div onClick={() => window.location.href = "https://api.whatsapp.com/send/?phone=33753070616&text&type=phone_number&app_absent=0"} className="flex-1 max-md:max-w-full">+33670956413</div>
        <img
          onClick={() => window.location.href = "https://api.whatsapp.com/send/?phone=33753070616&text&type=phone_number&app_absent=0"}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ddea49ac4cad90f6cf09681758505335e33ab592e0f09ec4c367108a0282e45?"
          className="shrink-0 border-white fill-white w-[26px]"
        />
      </div>
      <div className="shrink-0 mt-3 h-px bg-stone-400 mb-[200px] rounded-[100px] max-md:max-w-full" />
      <div onClick={() => window.location.href = "https://tally.so/embed/nWAaov?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight="} className="mt-20 tracking-wide max-md:mt-10 max-md:max-w-full">
        Que pensez vous de l'outil ?
      </div>
      {/*<Menu></Menu>*/}
    </div>
    
  );
}

