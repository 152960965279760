import React from "react";
import { useNavigate } from "react-router-dom";

const ExploreButton = ({ children, image, prompt }) => {
  const navigate = useNavigate();

  const exploreModel = () => {
    navigate("/imagine?prompt=" + prompt);
  };

  return (
    <button
      onClick={() => exploreModel()}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="btn-border-gold rounded-lg w-24 h-32"
      alt="bottle"
    ></button>
  );
};

export { ExploreButton };
