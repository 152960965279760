import React from "react";

export default function Popup() {
    const [showPopup, setShowPopup] = React.useState(true);
    return (
      <>
        {showPopup && (
          <div 
            onClick={() => setShowPopup(false)}
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-start"
          >
            <div className="relative w-full flex justify-center">
              <div
                className="cursor-pointer" 
                style={{ animation: 'bounce 1s infinite alternate' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="40" height="40" fill="currentColor" className="text-white">
                  <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                </svg>
              </div>
            </div>
          </div>
        )}
        <style>
          {`
            @keyframes bounce {
              from {
                transform: translateY(200px);
              }
              to {
                transform: translateY(calc(100vh - 300px));
              }
            }
          `}
        </style>
      </>
    );
  }

  export { Popup };