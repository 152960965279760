import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import ProjectRoutes from "./Routes"; // Assurez-vous que le bon composant est importé
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated, user } = useAuth0();

  return (
    <div>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      {/*!isAuthenticated && <LoginButton />*/}
      {/*<br />
      <LogoutButton />*/}
      {/*user?.email*/}
      <ProjectRoutes /> {/* Utilisez ProjectRoutes pour définir vos routes */}
    </div>
  );
}

export default App;
