import { Helmet } from "react-helmet";
import { Img, Text, Heading, Slider } from "../../components";
import Footer from "../../components/Footer";
import React from "react";

export default function DarkwalkthroughThreePage() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <>
      <Helmet>
        <title>Boost Your Brand - Visual Content Walkthrough</title>
        <meta
          name="description"
          content="Elevate your brand with impactful, personalized visual content. Discover how with our walkthrough and take your brand to the next level."
        />
      </Helmet>

      {/* main content section */}
      <div className="w-full bg-gradient1">
        <div>
          {/* slider section */}
          <div className="relative h-[814px] md:h-auto">
            <div className="slider9fortyone flex w-full  pt-[11px]">
              <Slider
                autoPlay
                autoPlayInterval={2000}
                responsive={{ 0: { items: 1 }, 550: { items: 1 }, 1050: { items: 1 } }}
                renderDotsItem={(props) => {
                  return props?.isActive ? (
                    <div className="mr-1.5 inline-block h-[8px] w-[32px] cursor-pointer rounded bg-gradient3" />
                  ) : (
                    <div className="mr-1.5 inline-block h-[8px] w-[8px] cursor-pointer rounded bg-gray-800" />
                  );
                }}
                activeIndex={sliderState}
                onSlideChanged={(e) => {
                  setSliderState(e?.item);
                }}
                ref={sliderRef}
                items={[...Array(3)].map(() => (
                  <React.Fragment key={Math.random()}>
                    <div className="flex flex-col gap-6">
                      <div className="container-xs md:p-5">
                        <div className="flex items-center justify-between gap-5">
                          <Heading as="h1" className="tracking-[0.20px]">
                            9:41
                          </Heading>
                          <div className="flex items-center gap-[5px] self-end">
                            <Img src="images/img_thumbs_up.svg" alt="thumbs up icon" className="h-[10px] self-end" />
                            <Img src="images/img_settings.svg" alt="settings icon" className="h-[10px]" />
                            <Img src="images/img_instagram.svg" alt="instagram icon" className="h-[13px]" />
                          </div>
                        </div>
                      </div>
                      <div className="relative h-[758px]">
                        <div className="absolute bottom-[0.00px] left-0 right-0 m-auto w-full">
                          <div className="relative z-[1] h-[140px] bg-gradient2" />
                          <div className="relative mt-[-2px] flex bg-gradient1 py-6 sm:py-5">
                            <div className="container-xs mb-14 mt-2 flex md:p-5">
                              <div className="flex w-full flex-col items-center gap-[27px]">
                                <Heading size="s" as="h2" className="mt-2">
                                  Boostez votre marque
                                </Heading>
                                <Text as="p" className="tracking-[0.20px]">
                                  Avec du contenu visuel percutant, personalisé à votre image.
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="absolute left-0 right-0 top-[0.00px] z-[2] m-auto h-[650px] w-[25%] md:h-auto">
                          <Img src="images/img_image.png" alt="image" className="h-[650px] w-full object-cover" />
                          <Img
                            src="images/img_group.png"
                            alt="image"
                            className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[622px] w-[90%] object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              />
            </div>
          </div>

          {/* footer navigation section */}
          <Footer />
        </div>
      </div>
    </>
  );
}
