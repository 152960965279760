   // src/components/LoginButton.js
   import React from "react";
   import { useAuth0 } from "@auth0/auth0-react";
   import { Button } from "../Button";


   const LoginButton = () => {
     const { loginWithRedirect } = useAuth0();

     return <Button onClick={() => loginWithRedirect()} shape="round" className="min-w-[320px] sm:px-5">Log In</Button>;
   };

   export default LoginButton;