import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Header from "../../components/Header";
import React, { useState, useRef } from "react";
import { Loading } from "components/Loading";
import ReactModal from "react-modal";
import { Generate } from "components/Generate";
import { Menu } from "components/Menu";
import { ExploreButton } from "components/ExploreButton";
import { useNavigate } from "react-router-dom";

export default function ExplorePage() {
  let navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  return (
    <>
      <Helmet>
        <title>Explorez</title>
        <meta name="description" content="version zero." />
      </Helmet>

      {/* status bar section */}
      <div className="w-full h-full min-h-screen h-dvh bg-gradient1 text-white p-5">
        <div className="flex flex-col container-xs  gap-[30px] sm:gap-[20px]">
          <div className="flex justify-between items-center">
            <div className="font-bold text-2xl">
              <button onClick={() => navigate(-1)}>&#8592;</button>
            </div>
            <div className="font-bold text-2xl">Explorer les modèles</div>
            <div></div>
          </div>
          <div className="rounded bg-black-700">
            <input
              type="text"
              placeholder="🔎︎ Rechercher"
              className=" w-full m-2 h-8 "
            ></input>
          </div>
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Intérieur</span>
              <button
                onClick={() => routeTo("/category")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div className="flex justify-between gap-2 mt-5">
              <ExploreButton
                prompt={5}
                image={"images/bottles/5.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={6}
                image={"images/bottles/6.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={7}
                image={"images/bottles/7.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={8}
                image={"images/bottles/8.png"}
              ></ExploreButton>
            </div>
          </div>
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Naturel</span>
              <button
                onClick={() => routeTo("/category")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div className="flex justify-between gap-2 mt-5">
              <ExploreButton
                prompt={9}
                image={"images/bottles/9.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={10}
                image={"images/bottles/10.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={11}
                image={"images/bottles/11.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={12}
                image={"images/bottles/12.png"}
              ></ExploreButton>
            </div>
          </div>
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Minimaliste</span>
              <button
                onClick={() => routeTo("/category")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div className="flex justify-between gap-2 mt-5">
              <ExploreButton
                prompt={13}
                image={"images/bottles/13.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={14}
                image={"images/bottles/14.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={15}
                image={"images/bottles/15.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={16}
                image={"images/bottles/16.png"}
              ></ExploreButton>
            </div>
          </div>
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Comptoirs</span>
              <button
                onClick={() => routeTo("/category")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div className="flex justify-between gap-2 mt-5">
              <ExploreButton
                prompt={17}
                image={"images/bottles/17.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={18}
                image={"images/bottles/18.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={19}
                image={"images/bottles/19.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={20}
                image={"images/bottles/20.png"}
              ></ExploreButton>
            </div>
          </div>
          
          <div>
            <div className="text-arrow">
              <span className="text-2xl font-bold">Effet Bokeh</span>
              <button
                onClick={() => routeTo("/category")}
                className="text-red-300 text-2xl"
              >
                &#x2192;
              </button>
            </div>
            <div className="flex justify-between gap-2 mt-5">
              <ExploreButton
                prompt={21}
                image={"images/bottles/21.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={22}
                image={"images/bottles/22.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={23}
                image={"images/bottles/23.png"}
              ></ExploreButton>
              <ExploreButton
                prompt={24}
                image={"images/bottles/24.png"}
              ></ExploreButton>
            </div>
          </div>
        </div>
      </div>
      <Menu></Menu>
    </>
  );
}
