import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Header from "../../components/Header";
import LogoutButton from "../../components/LogoutButton";
import LoginButton from "../../components/LoginButton";
import React, { useState, useEffect, useContext } from "react"; // Ajoutez useContext ici
import { Loading } from "components/Loading";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

export default function DarksplashscreenPage() {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      navigate('/DarkwalkthroughTwo'); // Remplacez '/new-page' par le chemin de la page vers laquelle vous voulez rediriger
    }, 3000); // 3000 millisecondes = 3 secondes

    return () => clearTimeout(timer); // Cela garantit que le timer est nettoyé si le composant est démonté avant que le timer ne se termine
  }, [navigate]);
  //const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated === undefined) {
    //return <div>Loading...</div>; // Ou un autre indicateur de chargement
  }
  return (
    <>
    {/*isAuthenticated ? (
        <p>Vous êtes connecté</p>
      ) : (
        <p>Vous n'êtes pas connecté</p>
      )*/}
      <Helmet>
        <title>Bienvenue dans l'App </title>
        <meta
          name="description"
          content="version zero."
        />
      </Helmet>

      {/* status bar section */}
      <div className="w-full bg-gradient1 pb-[189px] md:pb-5">
        {/* logo and loading section */}
        <div className="flex h-screen flex-col items-center justify-center">
          <Header />
          <div className="container-xs flex flex-col items-center gap-[123px] md:gap-[92px] sm:gap-[61px]">
            <div className="rounded-[66px] shadow-xs" >
              <Img
                  src="images/Vector.png"
                  alt="frame"
                  className="relative h-[auto] w-[auto]"
                />
              </div>
            <Loading isLoading={isLoading} className="h-[121px] w-[121px] mt-10  self-center object-cover" ></Loading>
          </div>
        </div>
        {/* Utilisez isAuthenticated pour conditionnellement rendre le bouton de connexion/déconnexion */}
        {/*isAuthenticated ? <LogoutButton /> : <LoginButton />*/}
      </div>
    </>
  );
}