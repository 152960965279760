import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const shapes = {
  round: "rounded-[29px]",
};
const variants = {
  gradient: {
    gray_900_pink_900_01: "bg-gradient text-white-A700",
  },
  fill: {
    red_300: "bg-red-300 text-white-A700",
    gray_900_01: "bg-gray-900_01 text-white-A700",
  },
};
const sizes = {
  xs: "h-[58px] px-[35px] text-base",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "red_300",
  to = "/",
  ...restProps
}) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <button
        className={`${className} flex flex-row items-center justify-center text-center cursor-pointer text-white-A700 tracking-[0.20px] text-base font-bold rounded-[29px] ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
        {...restProps}
      >
        {!!leftIcon && leftIcon}
        {children}
        {!!rightIcon && rightIcon}
      </button>
    </Link>
  );
};


Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs"]),
  variant: PropTypes.oneOf(["gradient", "fill"]),
  color: PropTypes.oneOf(["gray_900_pink_900_01", "red_300", "gray_900_01"]),
};

export { Button };
