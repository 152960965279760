import React, { useEffect, useState } from "react";
import { Loading } from "components/Loading";

const Generate = ({ children, form, close }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (form) {
      getImage().then();
    }
  }, [form]);

  const getImage = async () => {
    console.log("get image", FormData);
    const url = "https://image-api.photoroom.com/v2/edit";
    let info = "38f8b9fb773b0fb06864af094c3779ea9b9a8949";
    const options = {
      method: "POST",
      headers: {
        Accept: "image/png, application/json",
        "x-api-key": info,
      },
    };

    options.body = form;

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const data = await response.blob();
      console.log(response);
      const modifiedDate = new Date().getTime();
      const fileName = `Generated_${modifiedDate}.png`;
      setFileName(fileName);
      setImageUrl(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const share = async (blob) => {
    const file = new File([blob], fileName, {
      type: "image/png",
    });
    const shareData = {
      title: "Generated image",
      text: "Generated from ma cuvee",
      files: [file],
    };
    try {
      if (!navigator.canShare(shareData)) {
        throw new Error("Can't share data.", shareData);
      }
      await navigator.share(shareData);
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  const download = async (blob) => {
    try {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  return (
    <div className="w-full h-screen overflow-auto text-white">
      <div className="flex  flex-col container-xs gap-[30px] sm:gap-[20px] p-5">
        <div className="flex justify-between items-center">
          <div className="font-bold text-2xl">
            <button
              onClick={() => {
                if (close) {
                  close();
                }
              }}
            >
              &#8592;
            </button>
          </div>
          <div className="font-bold text-2xl">Retour</div>
          <div></div>
        </div>
        <div className="flex justify-center items-center">
          {isLoading && (
            <div className="rounded h-96 w-96 bg-gradient1 flex flex-col justify-center items-center">
              <Loading className="h-[121px] w-[121px] mt-10 self-center object-cover" isLoading={isLoading}></Loading>
              <span className="mt-5">En cours...</span>
            </div>
          )}
          {imageUrl && (
            <img
              alt="not found"
              className="rounded max-w-96 max-h-96"
              src={URL.createObjectURL(imageUrl)}
            />
          )}
        </div>
        {imageUrl && <div>{form?.get("background.prompt")}</div>}
        <div className="flex justify-between items-center mt-5">
          <button
            className="bg-gradient1 rounded w-24 h-24 text-xs font-bold flex flex-col justify-center items-center"
            onClick={() => {
              if (close) {
                close();
              }
            }}
          >
            <img src="images/refresh.svg" alt="refresh" />
            <span className="mt-2">Recommencer</span>
          </button>
          <button
            className="btn-gold rounded  w-24 h-24 text-xs font-bold flex flex-col justify-center items-center"
            onClick={() => download(imageUrl)}
          >
            <img src="images/download.svg" alt="download" />
            <span className="mt-2">Télécharger ma photo</span>
          </button>
          <button
            className="bg-gradient1 rounded  w-24 h-24 text-xs font-bold flex flex-col justify-center items-center"
            onClick={async () => await share(imageUrl)}
          >
            <img src="images/edit.svg" alt="edit" />
            <span className="mt-2">Partager</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export { Generate };
