import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Header from "../../components/Header";
import React, { useState, useRef } from "react";
import { Loading } from "components/Loading";
import ReactModal from "react-modal";
import { Generate } from "components/Generate";
import { Menu } from "components/Menu";
import { useNavigate } from "react-router-dom";

export default function Detourer() {
  let navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSize, setSelectedSize] = useState(1);
  const [selectedShadow, setSelectedShadow] = useState(1);
  //const [promptText, setPromptText] = useState("");
  const [selectedColor, setSelectedColor] = useState(1);
  const [formData, setFormData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  ReactModal.setAppElement("#root");

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectedImage(fileUploaded);
  };

  const generate = () => {
    let outputSize = "";
    let outputColor = "";
    let shadowMode = "";
    switch (selectedSize) {
      case 2:
        outputSize = "1080x1920";
        break;
      case 3:
        outputSize = "1920x1080";
        break;
      case 4:
        outputSize = "1200x1600";
        break;
      default:
        outputSize = "1500x1500";
        break;
    }
    switch (selectedShadow) {
      case 2:
        shadowMode = "ai.floating";
        break;
      case 3:
        shadowMode = "ai.hard";
        break;
      default:
        shadowMode = "ai.soft";
        break;
    }
    switch (selectedColor) {
      case 2:
        outputColor = "FFFFFFFF";
        break;
      case 3:
        outputColor = "000000FF";
        break;
      case 4:
        outputColor = "FFECD6FF";
        break;
      case 5:
        outputColor = "7A4729FF";
        break;
      case 6:
        outputColor = "F44336FF";
        break;
      case 7:
        outputColor = "03A9F4FF";
        break;
      case 8:
        outputColor = "4CAF50FF";
        break;
      default:
        outputColor = "00000000";
        break;
    }
    //let backgroundPrompt = promptText;

    const form = new FormData();
    // native seeds https://docs.photoroom.com/image-editing-api-plus-plan/ai-backgrounds
    form.append("background.seed", 117879368);
    form.append("background.color", outputColor);
    form.append("imageFile", selectedImage);
    form.append("outputSize", outputSize);
    form.append("shadow.mode", shadowMode);
    form.append("margin", 0.1);

    setFormData(form);
    setOpenModal(true);
  };

  return (
    <>
      <Helmet>
        <title>Détourez</title>
        <meta name="description" content="version zero." />
      </Helmet>

      {/* status bar section */}
      <div className="w-full min-h-screen h-dvh bg-gradient1 text-white">
        <div className="flex min-h-screen flex-col container-xs  gap-[30px] sm:gap-[20px] p-5">
          <div className="flex justify-between items-center">
            <div className="font-bold text-2xl">
              <button onClick={() => navigate(-1)}>&#8592;</button>
            </div>
            <div className="font-bold text-2xl">Détourez</div>
            <div></div>
          </div>
          <div className="flex justify-center items-center">
            <button className="rounded h-48 w-48 upload" onClick={handleClick}>
              <div className="flex flex-col items-center text-red-300">
                <span className="text-6xl">+</span>
                <span>Téléchargez</span>
              </div>
              {selectedImage && (
                <img alt="not found" src={URL.createObjectURL(selectedImage)} />
              )}
            </button>
            <input
              type="file"
              onChange={handleChange}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
          </div>
          <div className="text-arrow">
            <span className="text-2xl font-bold">
              Choisir sa couleur de fond
            </span>
          </div>
          <div className="flex flex-row justify-between gap-2 overflow-auto w-full shrink-0">
            <button
              className={`${selectedColor === 1 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(1)}
            >
              <div className=" w-16 h-16">
                <img
                  className="flex rounded w-16 h-16"
                  src="images/transparent.png"
                  alt="transparent"
                ></img>
              </div>
            </button>
            <button
              className={`${selectedColor === 2 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(2)}
            >
              <span className="flex rounded bg-white w-16 h-16"></span>
            </button>
            <button
              className={`${selectedColor === 3 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(3)}
            >
              <span className="flex rounded bg-black w-16 h-16"></span>
            </button>
            <button
              className={`${selectedColor === 4 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(4)}
            >
              <span
                style={{ backgroundColor: "#FFECD6" }}
                className="flex rounded w-16 h-16"
              ></span>
            </button>
            <button
              className={`${selectedColor === 5 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(5)}
            >
              <span
                style={{ backgroundColor: "#7A4729" }}
                className="flex rounded w-16 h-16"
              ></span>
            </button>
            <button
              className={`${selectedColor === 6 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(6)}
            >
              <span
                style={{ backgroundColor: "#F44336" }}
                className="flex rounded bg-red w-16 h-16"
              ></span>
            </button>
            <button
              className={`${selectedColor === 7 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(7)}
            >
              <span
                style={{ backgroundColor: "#03A9F4FF" }}
                className="flex  rounded w-16 h-16"
              ></span>
            </button>

            <button
              className={`${selectedColor === 8 ? "selected-color" : ""}`}
              onClick={() => setSelectedColor(8)}
            >
              <span
                style={{ backgroundColor: "#4CAF50" }}
                className="flex rounded w-16 h-16"
              ></span>
            </button>
          </div>
          <div className="text-arrow">
            <span className="text-2xl font-bold">Choisir son format</span>
          </div>
          <div className="flex flex-row justify-between">
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 1 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(1)}
            >
              <span className="format-square"></span>1 : 1
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 2 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(2)}
            >
               <span className="format-portrait"></span>9 : 16
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 3 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(3)}
            >
               <span className="format-rectangle"></span>16 : 19
            </button>
            <button
              className={`btn-border-gold rounded-full w-20 h-8 " + ${
                selectedSize === 4 ? "selected" : ""
              }`}
              onClick={() => setSelectedSize(4)}
            >
               <span className="format-quarter"></span>3 : 4
            </button>
          </div>

          <div className="text-arrow">
            <span className="text-2xl font-bold">Choisir son ombre</span>
          </div>
          <div className="flex flex-row justify-between">
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 1 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(1)}
            >
              Douce
            </button>
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 2 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(2)}
            >
              Moyenne
            </button>
            <button
              className={`btn-border-gold rounded-full w-24 h-8 " + ${
                selectedShadow === 3 ? "selected" : ""
              }`}
              onClick={() => setSelectedShadow(3)}
            >
              Forte
            </button>
          </div>
          {/*<div>
            <textarea
              className="rounded bg-black-700 w-full h-48 p-5"
              placeholder="Décrivez ce que vous souhaitez derrière votre produit (en anglais si possible).
Par exemple : a table of marble"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea>
          </div>*/}
          <div>
            <button
              className="rounded-full font-bold btn-gold w-full h-16"
              onClick={() => generate()}
            >
              Generer
            </button>
          </div>
        </div>

        <ReactModal
          style={{ overlay: { zIndex: 100 } }}
          className="bg-gradient1"
          isOpen={openModal}
        >
          <Generate
            form={formData}
            close={() => setOpenModal(false)}
          ></Generate>
        </ReactModal>
        <Menu></Menu>
      </div>
    </>
  );
}
