   // src/components/LoginButton.js
   import React from "react";
   import { useAuth0 } from "@auth0/auth0-react";
   import { Button } from "../Button";


   const LogoutButton = () => {
     const { logout } = useAuth0();

     return <Button onClick={() => logout()} shape="round" className="min-w-[280px] sm:px-5">Se déconnecter</Button>;
   };



   export default LogoutButton;