import { Helmet } from "react-helmet";
import { Img } from "../../components";
import Header from "../../components/Header";
import React, { useState, useRef } from "react";
import { Loading } from "components/Loading";
import ReactModal from "react-modal";
import { Generate } from "components/Generate";
import { Menu } from "components/Menu";
import { ExploreButton } from "components/ExploreButton";
import { useNavigate } from "react-router-dom";

export default function CategoryPage() {
  let navigate = useNavigate();

  const routeTo = (url) => {
    navigate(url);
  };

  return (
    <>
      <Helmet>
        <title>Explorez catégorie</title>
        <meta name="description" content="version zero." />
      </Helmet>

      {/* status bar section */}
      <div className="w-full min-h-screen h-dvh bg-gradient1 text-white p-5">
        <div className="flex flex-col container-xs  gap-[30px] sm:gap-[20px]">
          <div className="flex justify-between items-center">
            <div className="font-bold text-2xl">
              <img alt="logo" className="h-4" src="images/logo.png" />
            </div>
            <div className="font-bold text-2xl">Intérieur</div>
            <div>
              <span className="text-2xl">🔎︎</span>
            </div>
          </div>
          <div>
            <button onClick={() => routeTo("/explore")}>
              <img
                src="images/interior.png"
                className="w-full"
                alt="interior"
              ></img>
            </button>
          </div>
        </div>
      </div>
      <Menu></Menu>
    </>
  );
}
