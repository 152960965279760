
import React, { useEffect } from 'react';

function ContactPage() {
  useEffect(() => {
    const timeout = setTimeout(() => {
      // Redirige vers l'URL externe après 3 secondes
      window.location.replace("https://tally.so/embed/nWAaov?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=");
    }, 1);

    return () => clearTimeout(timeout);
  }, []);

  return <p>Redirection dans 3 secondes...</p>;
}

export default ContactPage;

